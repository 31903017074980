/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { graphql, navigate } from 'gatsby';
import { Layout, ProjectArea, ProfileCard } from '@components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const StyledMainContainer = styled.main`
  counter-reset: section;
  margin-top: 140px;
`;

const ProjectsPage = props => {
  const { location, data } = props;

  const handleGoBack = () => navigate('/');
  const handleGoToViewAllProjecs = () => navigate('/projects');

  return (
    <Layout location={location}>
      <StyledMainContainer className="fillHeight">
        <ProfileCard />

        <div className="flex justify-between pb-2 mt-24">
          <button
            className={`
              flex
              border-solid 
              border-4
              border-teal-300
              hover:text-cyan-900
              hover:bg-teal-300
              cursor-pointer
              rounded-lg
              font-bold
              p-1
              m-1
              bg-teal-300 
              text-cyan-900
              gap-2
            `}
            onClick={() => handleGoBack()}>
            <FontAwesomeIcon icon={faArrowLeft} />
            <span>Back</span>
          </button>

          <button
            className={`
              border-solid 
              border-4
              border-teal-300
              hover:text-cyan-900
              hover:bg-teal-300
              cursor-pointer
              rounded-lg
              font-bold
              p-1
              m-1
              bg-teal-300 
              text-cyan-900
              gap-2
            `}
            onClick={() => handleGoToViewAllProjecs()}>
            <span>View all Projects 😎</span>
          </button>
        </div>
        <div>
          <h2 className="heading-with-lines justify-center my-16">Take a look at my work!</h2>
        </div>

        <ProjectArea pageData={data.markdownRemark} />
      </StyledMainContainer>
    </Layout>
  );
};

ProjectsPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      frontmatter {
        title
        slug
        cover {
          childImageSharp {
            gatsbyImageData(width: 1400, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
        summary
        external
        tech
        github
      }
      html
    }
  }
`;

export default ProjectsPage;
